import React, { useState } from 'react';
import styles from './Index.module.css';
import simpleParallax from 'simple-parallax-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faGift,
    faAward,
} from '@fortawesome/free-solid-svg-icons';
import LinkButton from '../components/LinkButton';

function Index() {
    const meachiClassName = styles.meachi;
    const [meachiVisibility, setMeachiVisibility] = useState(false);

    const onMeachiLoaded = () => {
        registerParallax();
        showMeachi();
    };
    const registerParallax = () => {
        const meachi = document.getElementsByClassName(meachiClassName);
        // simpleParallax.js では画像がロードされていないタイミングで初期化するとぶっこわれる性質があるのでちょっと待つ
        new simpleParallax(meachi, {
            delay: 0.6,
            transition: 'cubic-bezier(0,0,0,1)',
        });
    };
    const showMeachi = () => {
        setMeachiVisibility(true);
    };

    return (
        <>
            <div
                className={`${styles.defaultFont} flex flex-col min-w-screen min-h-screen`}
            >
                <header className="min-w-full min-h-screen flex flex-col justify-end">
                    <h1
                        className={`${styles.cursive} ${styles.fadeIn} ${styles.rightBorderBox} text-right py-4 pr-8 lg:ml-16 ml-8 my-8 mr-auto lg:text-8xl text-5xl sm:text-3xl font-bold`}
                    >
                        Happy Birthday
                        <br />
                        and Happy First Anniversary,
                        <br />
                        Mea!
                    </h1>
                    <p className="animate-bounce text-center text-5xl p-4">
                        <FontAwesomeIcon icon={faChevronDown} />
                    </p>
                </header>
                <main className="flex flex-col justify-center items-center py-16 lg:px-32 px-8 min-h-screen">
                    <div
                        className={`${styles.contentInnerContainer} flex flex-col justify-center items-center w-full border lg:px-32 px-16 py-32 backdrop-blur backdrop-brightness-50`}
                    >
                        <section>
                            <h1 className="text-3xl text-center py-4">
                                <FontAwesomeIcon icon={faGift} />
                            </h1>
                        </section>
                        <section className="w-full  mx-8">
                            <h2 className="text-2xl font-bold py-4">
                                <FontAwesomeIcon
                                    icon={faAward}
                                    className="pr-4"
                                />{' '}
                                誕生日&一周年記念 イラスト
                            </h2>
                            <img
                                src="/assets/images/meachi_thumb.png"
                                alt="めあち！"
                                className={styles.imgBox}
                            />
                            <p>
                                Illustrated by{' '}
                                <a
                                    className="text-violet-300 hover:underline"
                                    href="https://twitter.com/kinokomusin"
                                >
                                    きのこむし
                                </a>
                            </p>
                            <LinkButton
                                href="https://assets.birth.meachi.me/%E3%81%8D%E3%81%AE%E3%81%93%E3%82%80%E3%81%97%E5%85%88%E7%94%9F%20%E3%82%81%E3%81%82%E3%81%A1%E8%AA%95%E7%94%9F%E6%97%A5%E3%83%BB%E4%B8%80%E5%91%A8%E5%B9%B4%E8%A8%98%E5%BF%B5%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88.zip"
                                className="mx-auto"
                            />
                        </section>
                        <section className="w-full mx-8">
                            <h2 className="text-2xl font-bold py-4">
                                <FontAwesomeIcon
                                    icon={faAward}
                                    className="pr-4"
                                />{' '}
                                誕生日&一周年記念 楽曲
                            </h2>
                            <img
                                src="/assets/images/stella_blur.png"
                                alt="ステラ"
                                className={styles.imgBox}
                            />
                            <p className="text-center">Coming soon...</p>
                        </section>
                    </div>
                </main>
                <footer className="flex flex-col justify-center items-center py-16 lg:px-32 px-8">
                    <div
                        className={`${styles.contentInnerContainer} border p-8 backdrop-blur backdrop-brightness-50`}
                    >
                        <h1 className="font-extrabold">Notice</h1>
                        <ul className="list-disc">
                            <li>
                                本サイトのコンテンツの権利はそれぞれの制作者に帰属します
                            </li>
                            <li>
                                本サイトのコンテンツは個人利用のみを許可するものとし、無断転載を禁じます
                            </li>
                            <li>
                                ただし、棺乃めあ。さんにおいては、本サイトのコンテンツを活動(Twitter・動画サイト・配信サイトでの利用、グッズ頒布。いずれも有償・無償問わない)において無償かつ無制限に利用できるものとします
                            </li>
                        </ul>
                    </div>
                    <div className={`${styles.contentInnerContainer} py-8`}>
                        <p className="text-center">(C)opyright 2023 usagiga.</p>
                    </div>
                </footer>
            </div>
            <div className={styles.backgroundContainer}>
                <img
                    className={`${meachiClassName} ${
                        meachiVisibility ? styles.fadeIn : ''
                    }`}
                    src="/assets/images/meachi_chara.png"
                    alt="meachi chara"
                    onLoad={onMeachiLoaded}
                />
            </div>
        </>
    );
}

export default Index;
