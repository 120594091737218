import React, { FC } from 'react';
import styles from './LinkButton.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

interface LinkButtonProps {
    href: string;
    className?: string;
}

const LinkButton: FC<LinkButtonProps> = (props) => {
    return (
        <>
            <a
                className={`${styles.linkButton} ${styles.linkBorderButton} text-2xl sm:text-base my-4 px-8 py-4 text-center ${props.className}`}
                href={props.href}
            >
                <FontAwesomeIcon icon={faDownload} /> Download
            </a>
        </>
    );
};

export default LinkButton;
